import { createTheme } from '@mui/material/styles';


const theme = createTheme({

  palette: {
    primary: {
      main: '#800080', 
    },
  },
});


export {theme}
